<template>
	<h1>服务列表</h1>
</template>

<script>
export default {
	name: "info-data-service-list"
}
</script>

<style scoped>

</style>
